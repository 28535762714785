import { createSlice } from "@reduxjs/toolkit";
import { createEducation, getAllEducationByLevels, updateSequence } from "./EducationFunction/EducationFunction";

const initialState = {
    isError: false,
    isSuccess: false,
    isLoading: false,
    error: "",
    education: {},
    educations: [],
    media: [],
    message: "",
  };

  export const educationSlice = createSlice({
    name: "education",
    initialState,
    reducers: {
      resetEducation: (state) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = false;
        state.message = "";
        state.education = {};
        state.educations = [];
        state.media = [];
      },
      currentEditeducation: (state, action) => {
        state.education = action.payload;
      },
      setMedia: (state, action) => {
        state.media = action.payload;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(createEducation.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(createEducation.fulfilled, (state) => {
          state.isLoading = false;
          state.isSuccess = true;
        })
        .addCase(createEducation.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          state.message = action.payload;
        })
        .addCase(getAllEducationByLevels.pending, (state) => {
            state.isLoading = true;
          })
          .addCase(getAllEducationByLevels.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.educations = action.payload;
          })
          .addCase(getAllEducationByLevels.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
            state.educations = null;
          })  .addCase(updateSequence.pending, (state) => {
            state.isLoading = true;
          })
          .addCase(updateSequence.fulfilled, (state) => {
            state.isLoading = false;
            state.isSuccess = true;
          })
          .addCase(updateSequence.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
          });
       
    },
  });
  
  export const { resetEducation, currentEditEducation, setMedia } = educationSlice.actions;
  export default educationSlice.reducer;
  