import axios from "axios";

const API_URL =  process.env.REACT_APP_BACKEND_API_URL + "/api/education/";

// Create Education
const createEducation = async (education, token) => {
    const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

    const response = await axios.post(API_URL + "create", education, config);
  
    return response.data;
  };

    // Get all Education By levels
const getAllEducationByLevels = async (token, page) => {
 
  
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.get(API_URL + `getAllEducationByLevels` ,config);
    return response.data;
  }

  const updateSequence = async (destination, draggableId, token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.put(API_URL + `update-sequence/${draggableId}` ,destination, config);
    return response.data;
  }

  const EducationService = {
   createEducation,
   getAllEducationByLevels,
   updateSequence,
   };
   
   export default EducationService;