import { createAsyncThunk } from "@reduxjs/toolkit";
import EducationService from "../EducationService";

//Create Education
export const createEducation = createAsyncThunk(
  "education/create",
  async ({ education, token }, thunkAPI) => {
    try {
      return await EducationService.createEducation(education, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllEducationByLevels = createAsyncThunk(
  "education/getAllEducationByLevels",
  async ({ token }, thunkAPI) => {
    try {
      const educations = await EducationService.getAllEducationByLevels(token);
      return educations;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Sequence
export const updateSequence = createAsyncThunk(
  "education/updateSequence",
  async ({ destination, draggableId, token }, thunkAPI) => {
    try {
      const updatedSequence = await EducationService.updateSequence(
        destination,
        draggableId,
        token
      );
      return updatedSequence;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
