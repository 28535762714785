import { createAsyncThunk } from "@reduxjs/toolkit";
import NewsService from "../NewsService";

//Create News
export const createNews = createAsyncThunk(
    "news/create",
    async ({news, token },thunkAPI) => {
      try {
        return await NewsService.createNews(news, token);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error;
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  export const getAllNewsByPages = createAsyncThunk(
    "news/getAllNewsByPages",
    async ({token, page }, thunkAPI) => {
      
      try {
        const newsList = await NewsService.getAllNewsByPages(
          token,
          page
        );
        return newsList;
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error;
        return thunkAPI.rejectWithValue(message);
      }
    }
  );
  
    // Update News by id
export const updateNewsById = createAsyncThunk(
  "news/updateNewsById",
  async ({ id, editFormData, token }, thunkAPI) => {
    try {
      const news = await NewsService.updateNews(
        id,
        editFormData,
        token
      );
      return news;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error;
      return thunkAPI.rejectWithValue(message);
    }
  }
);



  
