import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Paper,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../components/CustomButton";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../News/News.css";
import { useNavigate } from "react-router-dom";
import SingleFileUploadWithProgress from "../../components/fileUpload/SingleFileUploadWithProgress";
import axios from "axios";
import { UploadError } from "../../components/fileUpload/UploadError";
import { useUser } from "../../context/UserContext";
import { createEducation } from "../../redux/Education/EducationFunction/EducationFunction";
import FileDropzone from "../../components/fileUpload/FileDropzone";

const CreateEducation = () => {
  const theme = useTheme();
  const isNonMobile = useMediaQuery("(min-width: 600px)");
  const [updateButtonDisabled, setUpdateButtonDisabled] = useState(false);
  const user = useUser();
  const [file, setFile] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
   
  
  const [parentFile, setParentFile] = useState([]); 
  const updateParentFile = (newFile) => {
    setParentFile(newFile);
  };

  const initialValues = {
    title: "",
    content: "",
    levels: "basic",
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("This field is Required"),
    content: Yup.string().required("This field is Required"),
    levels: Yup.string().required("Please select a levels"),
  });

  const onSubmit = async (values, { resetForm }) => {
    try {
      setUpdateButtonDisabled(true);

      const { title, content, levels } = values;

      const educationCreateData = {
        title,
        content,
        levels,
        media: file,
      };

      await dispatch(createEducation({ token: user.token, education: educationCreateData }));

      toast.success("Education Content Created!");
    } catch (error) {
      console.error("Error Creating Content:", error);
      toast.error("Failed to create content");
    } finally {
      setUpdateButtonDisabled(false);
      resetForm();
      navigate("/education");
    }
  };

  return (
    <Box>
      <CustomButton
        nonMobileText="Go Back"
        mobileText="Back"
        onClick={() => {
          navigate("/education");
        }}
      />
      <ToastContainer theme="colored" />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, handleChange, handleSubmit, errors, touched }) => (
          <form onSubmit={handleSubmit}>
            <Grid container sx={{ mt: 4 }}>
              <Grid item xs={12}>
                <Paper
                  elevation={3}
                  sx={{ p: isNonMobile ? "2rem 4rem" : "1rem" }}
                >
                  <Box sx={{ marginY: "2rem" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: "2rem",
                      }}
                    >
                      <h3>Create Your Content Here</h3>
                    </div>

                    <div
                      style={{
                        display: !isNonMobile && "flex",
                      }}
                    >
                      <h6>
                        Title:{" "}
                        {touched.title && !values.title && (
                          <span style={{ color: "red" }}>
                            *required {values.title}
                          </span>
                        )}
                      </h6>
                    </div>
                    <TextField
                      id="title"
                      fullWidth
                      sx={{ my: 2 }}
                      name="title"
                      value={values.title}
                      onChange={handleChange}
                    />

                    <div
                      style={{
                        display: !isNonMobile && "flex",
                      }}
                    >
                      <h6>
                      Levels:{" "}
                        {touched.title && !values.title && (
                          <span style={{ color: "red" }}>
                            *required {values.title}
                          </span>
                        )}
                      </h6>
                    </div>
                    <TextField
                      id="levels"
                      select
                      fullWidth
                      name="levels"
                      value={values.levels}
                      onChange={handleChange}
                      error={touched.levels && Boolean(errors.levels)}
                      helperText={touched.levels && errors.levels}
                      sx={{ my: 2 }}
                    >
                      <MenuItem value="basic">Basic</MenuItem>
                      <MenuItem value="intermediate">Intermediate</MenuItem>
                      <MenuItem value="advanced">Advanced</MenuItem>
                    </TextField>

                    <div
                      style={{
                        display: !isNonMobile && "flex",
                      }}
                    >
                      <h6>
                        Content:{" "}
                        {touched.content && !values.content && (
                          <span style={{ color: "red" }}>*required </span>
                        )}
                      </h6>
                    </div>

                    <ReactQuill
                      theme="snow"
                      value={values.content}
                      onChange={(content) => {
                        if (content === "<p><br></p>") {
                          content = "";
                        }
                        handleChange({
                          target: { name: "content", value: content },
                        });
                      }}
                      style={{
                        height: "300px",
                        marginBottom: isNonMobile ? "4rem" : "8rem",
                        overflowWrap: "break-word",
                      }}
                      modules={{
                        toolbar: [
                          [{ header: [1, 2, false] }],
                          [
                            "bold",
                            "italic",
                            "underline",
                            "strike",
                            "blockquote",
                          ],
                          [{ list: "ordered" }, { list: "bullet" }],
                          ["link"],
                          ["clean"],
                        ],
                      }}
                      formats={[
                        "header",
                        "bold",
                        "italic",
                        "underline",
                        "strike",
                        "blockquote",
                        "list",
                        "bullet",
                        "link",
                      ]}
                      className="custom-toolbar"
                    />

                    <div
                      style={{
                        display: !isNonMobile && "flex",
                      }}
                    >
                      <h6>Upload Some Media:</h6>
                    </div>

                    <FileDropzone mediaType='education' updateParentFile={updateParentFile}/>


                    <div
                      style={{
                        marginTop: "1rem",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <CustomButton
                        type="submit"
                        nonMobileText="Create"
                        mobileText="Create"
                        disabled={
                          updateButtonDisabled ||
                          parentFile.some((fw) => !fw.cloudinary) // Check if any file is not uploaded
                        }
                      />
                    </div>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default CreateEducation;
