import { createSlice } from "@reduxjs/toolkit";
import { createNews, getAllNewsByPages, updateNewsById } from "./NewsFunction/NewsFunction";

const initialState = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  error: "",
  news: {},
  newsList: [],
  media: [],
  
  message: "",
};

export const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {
    resetNews: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
      state.news = {};
      state.newsList = [];
      state.media = [];

    },
    currentEditNews: (state, action) => {
      state.news = action.payload;
    },
    setMedia: (state, action) => {
      state.media = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createNews.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createNews.fulfilled, (state) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(createNews.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getAllNewsByPages.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllNewsByPages.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.newsList = action.payload;
      })
      .addCase(getAllNewsByPages.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.newsList = null;
      })
      .addCase(updateNewsById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateNewsById.fulfilled, (state) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(updateNewsById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { resetNews, currentEditNews, setMedia } = newsSlice.actions;
export default newsSlice.reducer;
