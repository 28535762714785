import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../context/UserContext";
import axios from "axios";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { UploadError } from "./UploadError";
import SingleFileUploadWithProgress from "./SingleFileUploadWithProgress";

const FileDropzone = ({ mediaType, updateParentFile, updateFile }) => {
  const theme = useTheme();
  const isNonMobile = useMediaQuery("(min-width: 600px)");
  const user = useUser();
  const [file, setFile] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      const mappedAcc = acceptedFiles.map((file) => ({ file, errors: [] }));
      const newFileState = [...file, ...mappedAcc, ...rejectedFiles];
      setFile(newFileState);
      updateParentFile(newFileState); // Call the callback to update parent's state
    },
    [file, updateParentFile]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg", ".jpg"],
      "video/mp4": [".mp4"],
      "video/mkv": [".mkv"],
    },
  });

  useEffect(() => {
    if(updateFile){
    setFile((curr) => [...curr, ...updateFile]);
    }
    // eslint-disable-next-line
  }, []);


  // useCallback to create a memoized version of onUpload
  const onUpload = useCallback(
    (file, cloudinary) => {
      setFile((curr) =>
        curr.map((fw) => {
          if (fw.file === file) {
            return { ...fw, cloudinary };
          }
          return fw;
        })
      );
      updateParentFile((curr) =>
        curr.map((fw) => {
          if (fw.file === file) {
            return { ...fw, cloudinary };
          }
          return fw;
        })
      ); // Call the callback to update parent's state
    },
    [updateParentFile]
  );

  async function onDelete(file, public_id) {
    const formData = {
      publicId: public_id,
    };
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const response = await axios.post(
        `http://localhost:5000/api/${mediaType}/delete`,
        formData,
        config
      );

      setTimeout(() => {
        setFile((curr) => curr.filter((fw) => fw.file !== file));
      }, 200);
      updateParentFile((curr) => curr.filter((fw) => fw.file !== file)); // Call the callback to update parent's state

      return response.data;
    } catch (error) {
      throw new Error("File deletion failed.");
    }
  }

  function onDeleteError(file) {
    const newFileState = file.filter((fw) => fw.file !== file);
    setFile(newFileState);
    updateParentFile(newFileState); // Call the callback to update parent's state
  }

  return (
    <>
      <div
        style={{
          marginTop: "1rem",
          border: "2px dotted gray",
          height: "80px",
          backgroundColor: isDragActive
            ? "lightblue" // color when file is on the div
            : theme.palette.background.alt,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div {...getRootProps()} style={{ width: "100%", height: "100%" }}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <p style={{ textAlign: "center" }}>Drop the files here ...</p>
          ) : isNonMobile ? (
            <p style={{ textAlign: "center" }}>
              Drag 'n' drop some files here, or click to select files <br />{" "}
              (Files should be not more than 35mb)
            </p>
          ) : (
            <p style={{ textAlign: "center" }}>
              Drag & Drop the files here ... <br /> (Files should be not more
              than 35mb)
            </p>
          )}
        </div>
      </div>
      {file.map((fileWrapper) => (
        <Grid item key={fileWrapper.id}>
          {fileWrapper.errors.length ? (
            <UploadError
              file={fileWrapper.file}
              errors={fileWrapper.errors}
              onDeleteError={onDeleteError}
            />
          ) : (
            <SingleFileUploadWithProgress
              file={fileWrapper.file}
              cloudinary={fileWrapper.cloudinary}
              onUpload={onUpload}
              onDelete={onDelete}
              mediaType={mediaType}
            />
          )}
        </Grid>
      ))}
    </>
  );
};

export default FileDropzone;
