
import axios from "axios";

const API_URL =  process.env.REACT_APP_BACKEND_API_URL + "/api/news/";

// Create News
 const createNews = async (news, token) => {
    const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
    const response = await axios.post(API_URL + "create", news, config);
  
    return response.data;
  };

   // Get all Content By Pages
const getAllNewsByPages = async (token, page) => {
 
  
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(API_URL + `getAllNewsByPages?page=${page}` ,config);
  return response.data;
}

const deleteNews = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  await axios.delete(API_URL + `delete/${id}`, config);
  return id;
};

const updateNews = async (id, editFormData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(
    API_URL + `update/${id}`,
    editFormData,
    config
  );
  return response.data; 
};

  
  const NewsService = {
    deleteNews,
  createNews,
  getAllNewsByPages,
  updateNews
   };
   
   export default NewsService;