import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Button, useMediaQuery, useTheme } from "@mui/material";
import { ToastContainer } from "react-toastify";
import Header from "../../components/Header";
import FlexBetween from "../../components/FlexBetween";
import { useNavigate } from "react-router-dom";
import { Add } from "@mui/icons-material";
import { useUser } from "../../context/UserContext";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from "react-redux";
import { getAllEducationByLevels, updateSequence } from "../../redux/Education/EducationFunction/EducationFunction";

const initialData = {
  basic: ['Item 1', 'Item 2', 'Item 3'],
  intermediate: ['Item 4', 'Item 5', 'Item 6'],
  advanced: ['Item 7', 'Item 8', 'Item 9'],
};

const groupColors = {
  basic: 'bg-blue-100',
  intermediate: 'bg-green-100',
  advanced: 'bg-yellow-100',
};
const EducationContent = () => {

  const dispatch = useDispatch();

 const handleDragEnd = result => {
    if (!result.destination) return;

    const { source, destination, draggableId } = result;

    
    const updatedData = { ...data };
    // Moving within the same list
    if (source.droppableId === destination.droppableId) {
      const items = Array.from(data[source.droppableId]);
      const [removed] = items.splice(source.index, 1);
      items.splice(destination.index, 0, removed);
      updatedData[source.droppableId] = items;
    } else {
      // Moving to a different list
      const sourceItems = Array.from(data[source.droppableId]);
      const destinationItems = Array.from(data[destination.droppableId]);
      const [removed] = sourceItems.splice(source.index, 1);
      destinationItems.splice(destination.index, 0, removed);
      updatedData[source.droppableId] = sourceItems;
      updatedData[destination.droppableId] = destinationItems;
    }
    const newDestination = { newGroup : destination.droppableId, newSequence: destination.index};

    dispatch(updateSequence({destination: newDestination, draggableId, token: user.token}))
    setData(updatedData);
  };

  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const user = useUser();
  const isNonMobile = useMediaQuery("(min-width: 942px)");
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [data, setData] = useState({})

  
  useEffect(() => {
    const getAllEducation = async () => {
      try {
        const appendData = await dispatch(getAllEducationByLevels({token: user.token}));
        setData(appendData.payload);
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };
  
    getAllEducation();
    // eslint-disable-next-line
  }, []);
  



  const AccordionSection = ({ title, content }) => (
    <Accordion expanded={expanded === content} onChange={handleChange(content)}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${content}bh-content`}
        id={`${content}bh-header`}
      >
        <Typography sx={{ width: "33%", flexShrink: 0 }}>{title}</Typography>
        <Typography sx={{ color: "text.secondary" }}>
          {content === "basic" &&
            "Start your learning journey with foundational concepts."}
          {content === "intermediate" &&
            "Build upon your basics and explore more advanced topics."}
          {content === "advanced" &&
            "Dive deep into advanced techniques and strategies."}
        </Typography>{" "}
      </AccordionSummary>
      <AccordionDetails>
        <Box className="p-2">
          {content === "basic" && (
            <Typography>Basic content goes here.</Typography>
          )}
          {content === "intermediate" && (
            <Typography>Intermediate content goes here.</Typography>
          )}
          {content === "advanced" && (
            <Typography>Advanced content goes here.</Typography>
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
  );

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Box
        display={"flex"}
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          mb: "3rem",
          marginX: isNonMobile && "50px",
        }}
      >
        <Header title="EDUCATION" />

        {(user.role === "admin" || user.role === "superadmin") && (
          <Box>
            <FlexBetween>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  navigate("/education/create");
                }}
                sx={{
                  color: "#000000",
                  backgroundColor: theme.palette.primary.light,
                }}
              >
                <Add /> {isNonMobile ? "Create Educational Content" : "create"}
              </Button>
            </FlexBetween>
          </Box>
        )}
      </Box>
      <ToastContainer theme="colored" />
{(user.role !== "admin" && user.role !== "superadmin") && (
 <div>
 <AccordionSection title="Basic" content="basic" />
 <AccordionSection title="Intermediate" content="intermediate" />
 <AccordionSection title="Advanced" content="advanced" />
</div> )
}
{(user.role === "admin" || user.role ==="superadmin") &&
 <div className="flex justify-center items-start ">
 <DragDropContext onDragEnd={handleDragEnd}>
   {Object.keys(data).map(groupKey => (
     <Droppable key={groupKey} droppableId={groupKey}>
       {(provided, snapshot) => (
         <div
           ref={provided.innerRef}
           className={`w-1/3 p-4 mx-2  min-h-[200px] ${
             snapshot.isDraggingOver ? 'bg-blue-100' : groupColors[groupKey]
           }`}
         >
           <h2 className="text-lg font-semibold mb-4 capitalize">
             {groupKey}
           </h2>
           {data[groupKey].map((item, index) => (
             <Draggable key={item._id} draggableId={item._id} index={index}>
               {(provided, snapshot) => (
                 <div
                   ref={provided.innerRef}
                   {...provided.draggableProps}
                   {...provided.dragHandleProps}
                   className={`p-2 mb-2 rounded-md ${
                     snapshot.isDragging
                       ? 'bg-blue-200'
                       : 'bg-gray-200'
                   }`}
                 >
                   {item.title}
                 </div>
               )}
             </Draggable>
           ))}
           {provided.placeholder}
         </div>
       )}
     </Droppable>
   ))}
 </DragDropContext>
</div>
}

     
  
     
                    
    </div>
  );
};

export default EducationContent;
