import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Formik} from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import {
  updateNewsById,
} from "../../redux/News/NewsFunction/NewsFunction";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./News.css";
import { useNavigate } from "react-router-dom";
import SingleFileUploadWithProgress from "../../components/fileUpload/SingleFileUploadWithProgress";
import axios from "axios";
import {UploadError} from "../../components/fileUpload/UploadError";
import { useUser } from "../../context/UserContext";
import CustomButton from "../../components/CustomButton";
import FileDropzone from "../../components/fileUpload/FileDropzone";

const EditNews = () => {
  const theme = useTheme();
  const isNonMobile = useMediaQuery("(min-width: 600px)");
  const [updateButtonDisabled, setUpdateButtonDisabled] = useState(false);
  const user = useUser();
  const news = useSelector((state) => state.news.news);
  const [parentFile, setParentFile] = useState([]); 
  const dispatch = useDispatch();
  const navigate = useNavigate();
 

  useEffect(() => {
    setParentFile((curr) => [...curr, ...news.media]);

    // eslint-disable-next-line
  }, []);



  const updateParentFile = (newFile) => {
    setParentFile(newFile);
  };

  const initialValues = {
    title: news.title,
    content: news.content,
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("This field is Required"),
    content: Yup.string().required("This field is Required"),
  });

  const onSubmit = async (values, { resetForm }) => {
    setUpdateButtonDisabled(true);
    const { title, content } = values;

    const editFormData = {
      title,
      content,
      media: parentFile,
    };

    try {
      await dispatch(
        updateNewsById({
          id: news._id,
          token: user.token,
          editFormData,
        })
      ).then(() => {toast.success("Content Succesfully Edited!"); });
      
    } catch (error) {
      console.error("Error Creating Content:", error);
      toast.error("Failed to create content");
    } finally {
      // Enable the update button after the request is complete (success or error)
      navigate("/news");
    }
  };

  return (
    <Box>
      <CustomButton
        nonMobileText="Go Back"
        mobileText="Back"
        onClick={() => {
          navigate("/news");
        }}
      />
      <ToastContainer theme="colored" />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, handleChange, handleSubmit, errors, touched }) => (
          <form onSubmit={handleSubmit}>
            <Grid container sx={{ mt: 4 }}>
              <Grid item xs={12}>
                <Paper
                  elevation={3}
                  sx={{ p: isNonMobile ? "2rem 4rem" : "1rem" }}
                >
                  <Box sx={{marginY: "2rem"}}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: "2rem",
                      }}
                    >
                      <h3>Edit Your Content Here</h3>
                    </div>

                    <TextField
                      label="Title"
                      id="title"
                      fullWidth
                      sx={{ my: 2 }}
                      name="title"
                      value={values.title}
                      onChange={handleChange}
                      error={errors.title && touched.title}
                      helperText={
                        touched.title && errors.title ? (
                          <span style={{ color: "red" }}>{errors.title}</span>
                        ) : null
                      }
                    />

                    <ReactQuill
                      theme="snow"
                      value={values.content}
                      onChange={(content) =>
                        handleChange({
                          target: { name: "content", value: content },
                        })
                      }
                      style={{
                        height: "300px",
                        marginBottom: "4rem",
                        overflowWrap: "break-word",
                      }}
                      modules={{
                        toolbar: [
                          [{ header: [1, 2, false] }],
                          [
                            "bold",
                            "italic",
                            "underline",
                            "strike",
                            "blockquote",
                          ],
                          [{ list: "ordered" }, { list: "bullet" }],
                          ["link"],
                          ["clean"],
                        ],
                      }}
                      formats={[
                        "header",
                        "bold",
                        "italic",
                        "underline",
                        "strike",
                        "blockquote",
                        "list",
                        "bullet",
                        "link",
                      ]}
                      className="custom-toolbar"
                    />

                    <div>
                      <h6>Upload Some Media</h6>
                    </div>

                    <FileDropzone mediaType='news' updateParentFile={updateParentFile} updateFile={news.media}/>
                    <div
                      style={{
                        marginTop: "1rem",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        type="submit"
                        sx={{
                          color: theme.palette.neutral[10],
                          backgroundColor: theme.palette.primary.main,
                        }}
                        disabled={
                          updateButtonDisabled ||
                          parentFile.some((fw) => !fw.cloudinary) // Check if any file is not uploaded
                        }
                      >
                        Edit
                      </Button>
                    </div>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default EditNews;
